module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0VK0K7Y637"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SIEM Project","short_name":"Agency","start_url":"/","background_color":"#ffffff","theme_color":"#fed136","display":"minimal-ui","icon":"content/assets/siem-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9ba0be78512337e11661e6d7cbfaf047"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
